<template>
  <div class="detail-list">
    <div class="ad" v-if="newBanner">
      <img :src="newBanner.webImg" />
    </div>
    <div class="detail-box" id="J_detailBox">
      <h2></h2>
      <div class="box">
          <div  class="menu" >
              <ul id="J_menu" :style="{top: t + 'px'}">
                  <li :class="current.id == item.id ?'active':''" v-for="item,index in news" :key="index" @click="changeTab(index)">
                      {{item.name}}
                  </li>
              </ul>
          </div>
          
          
          <ul class="list" v-if="current && newsList[current.id] && newsList[current.id].list.length > 0">
        
            <li v-for="(item,index) in newsList[current.id].list" :key="index">
                <router-link :to="{name: 'Detail',params: { id: item.id }}" class="list-item"  >
                  <img :src="item.webImg" alt="" />
                  <span class="item-con">
                      <h3 class="name">{{item.title}}</h3>
                      
                      <span class="desc" v-for="(_item,_itemindex) in item.subTitle.split('\n')" :key="_itemindex">
                        {{_item}}
                      </span>
                      <span class="btn">Details</span>
                  </span>
                </router-link>
              
            </li>
            
          </ul>
          <div class="empty" v-else>
              暂无新闻
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/api/Utils.js' 
let flag=true
export default {
  name: 'Detail-List',
  components: {
    
  },
  data(){
    return {
        pageSize: 2,
        newBanner: null,
        news: null,
        newsList: {},
        current: null,
        show: false,
        t: 0
    }
  },
  methods:{
    changeTab(index){
      this.current= this.news[index];
      flag = true;
      this.t = 0;
      if(!this.newsList[this.current.id]){
        this.newsList[this.current.id] = {
          page: 1,
          list: []
        }
        this.getdata();
      }
    },
    getElementTop(elem){

    　　var elemTop=elem ? elem.offsetTop : 0;//获得elem元素距相对定位的父元素的top

    　　elem=elem.offsetParent;//将elem换成起相对定位的父元素

    　　while(elem!=null){//只要还有相对定位的父元素 

    　　　　//获得父元素 距他父元素的top值,累加到结果中

    　　　　elemTop+=elem.offsetTop;

    　　　　//再次将elem换成他相对定位的父元素上;

    　　　　elem=elem.offsetParent;

    　　}

    　　return elemTop;

    },
    fun(){
        let _this = this;
        let detailBoxTop = this.getElementTop(document.body.getElementsByClassName('detail-box')[0])
        let listHeight = document.body.getElementsByClassName('list')[0] ? document.body.getElementsByClassName('list')[0].clientHeight : 0
        const clientHeight = document.body.clientHeight;
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight;
        if(scrollTop + clientHeight >= scrollHeight ){
            if(flag){
                flag = false;
                _this.getdata();
            }
        }
        if( scrollTop  > detailBoxTop && scrollTop < detailBoxTop + listHeight - 100){
          _this.t = scrollTop - detailBoxTop
        }else if (scrollTop  <= detailBoxTop){
          _this.t = 0;
        }
      
    },
    scollfun(){
      window.addEventListener('scroll', this.fun, false);
    },
    getdata(){
      let _d = this.newsList[this.current.id]
      let page = _d.page;
      Utils.post(`v1/outer/article/newList`, {pageNum:page,limit: this.pageSize,id: Number(this.current.id)}).then((res)=>{
        
        if(res && res.code == 200){
          let n = res.data;
          if(n){
              let list = _d.page == 1 ? n : _d.list.concat(n || []);
              if(n.length >= this.pageSize){
                page++;
                flag = true;
              }
              this.newsList[this.current.id] = {
                page: page,
                list: list
              }
              console.log(this.newsList)
              this.newsList = Object.assign({},this.newsList,this.newsList);
          }
          
        }
      })
    }
  },
  mounted(){
   
    Utils.post(`v1/outer/article/news`).then((res)=>{
        if(res && res.code == 200){
          this.newBanner = res.data.newBanner
          if(res.data.news && res.data.news.length > 0){
            this.news = res.data.news
            this.current = this.news[0]
            this.newsList[this.current.id] = {
              page: 1,
              list: []
            }
            this.getdata();
            this.$nextTick(()=>{
              this.scollfun();
            });
          }
        }
      })
  }
}
</script>
<style scoped lang="stylus">
.detail-list{
  background : #f7f8f8;
  padding-bottom: 120px;
}
.ad{
   height: 538px;
   overflow: hidden;
   position relative
   img{
      display block 
      height: 538px;
      margin: 0 auto;
      position absolute
      left: 50%
      transform translate3d(-50%,0,0)
      top: 0
   }
}
.detail-box{
  padding-top 167px
}
h2{
  margin: 0 0 90px;
  background: url('./title.png') 50% 0 no-repeat;
  background-size: auto 31px
  height 31px;
}
.box{
  display flex;
  width 841px
  margin: 0 auto
}
.menu{
  display: inline-block;
  width: 130px;
  margin-right: 30px;
  position relative
  ul{
    position: absolute
    top: 0
    width: 130px;
    z-index: 2
    left: 10px
    overflow auto
    transform top .5s ease
  }
  li{
    display block;
    text-indent: 14px;
    font-size: 14px;
    height 26px;
    line-height 26px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 130px;
    &.active,&:hover{
      color #fff;
      background #353e66;
      border-radius: 20px;
    }
  }
}
.empty{
  flex: 1;
  text-align center
  padding 100px 0
  font-size: 24px
}
.list{
  flex: 1;
  
  li{
    margin-bottom 30px
    &:hover{
      background #fff;
    }
    
  }
  .list-item{
      padding 10px;
      display flex
      color: #3e3a39;
      span{
        display block;
      }
  }
  img{
    width 322px;
    height 283px;
    display inline-block
  }
  .item-con{
      flex: 1
      text-align center
      padding-top: 70px;
      margin: 0 30px;
      .name{
          font-size: 16px;
          margin-bottom 15px;
          font-weight bold
          line-height 1.3
      }
      .desc{
          font-size: 12px;
          display block;
          line-height 2;
      }
  }
  .btn{
      width 107px;
      height 25px;
      line-height 25px;
      border 1px solid #d0d0d0;
      display: block;
      margin: 40px auto 0;
      border-radius 15px;
      
      &:hover,&.active{
        background : #353e66;
        border 1px solid #353e66;
        color : #fff;
      }
  }
}
</style>
